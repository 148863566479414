import "./pinko-item-picker.scss";
import template from "./pinko-item-picker.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoPicker } from "../pinko-picker/pinko-picker.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoItemPickerOptions } from "./types.js";
import { Log } from "@incinity/hiyo/log.js";
import { StringHelper } from "@incinity/hiyo/string-helper.js";
import { Messages } from "@incinity/hiyo/messages.js";

@component(template)
export class PinkoItemPicker<T extends Context = Context, U extends PinkoItemPickerOptions = PinkoItemPickerOptions> extends PinkoPicker<T, U> {

    // Properties
    public items: DropdownItem[];
    public selected: DropdownItem;
    public index: number;

    public async createItems(): Promise<void> {
        // Pass items from options if defined
        if (this.options.items?.length) {
            this.items = this.options.items;
        }
        else {
            Log.w(`${this.constructor.name}.createItems() not implemented. Forgot to override it?`);
        }
    }

    public keyUp(event: KeyboardEvent) {
        // Read value
        let value = this.dialog.querySelector<HTMLInputElement>("input").value;

        // Arrow keys are handled on down event
        if (event.key == "ArrowDown" || event.key == "ArrowUp") {
            return;
        }

        // Enter
        if (event.key == "Enter") {
            // Something selected?
            if (this.index) {
                this.dialog.querySelector<HTMLElement>(`div.item[tabindex="${this.index}"]`).click();
            }
            return;
        }

        // ESC
        if (event.key == "Escape") {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Close
            this.clear();
            return;
        }

        // Search for match
        this.search(value);
    }

    public keyDown(event: KeyboardEvent) {
        // Arrow up
        if (event.key == "ArrowUp") {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();
            event.preventDefault();

            // Select next item
            this.move(-1);
            return;
        }

        // Arrow down
        if (event.key == "ArrowDown") {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();
            event.preventDefault();

            // Select next item
            this.move(1);
            return;
        }
    }

    public focus(): void {
        // Reset index
        this.index = 0;

        // super call
        super.focus();
    }

    public move(step: number): void {
        // Not possible to move?
        if (!this.dialog.querySelector(`div.item[tabindex="${this.index + step}"]`)) {
            return;
        }

        // Get next element
        let previous = this.dialog.querySelector(`div.item[tabindex="${this.index}"]`);

        // Next index
        this.index += step;

        let next = this.dialog.querySelector(`div.item[tabindex="${this.index}"]`);

        // Update classes
        previous?.classList.remove("item-hovered");
        next.classList.add("item-hovered");
    }

    public search(term: string): void {
        // Minimum term length of 3 characters is required to start search
        // But search with null is possible to clear the results!

        // Reset cursor index
        this.index = 0;

        // HTML result
        let html = "";
        let items: DropdownItem[];

        // Search for non-nullable term
        // This is the main search routine, extend for new search rules
        if (!term?.length) {
            items = this.items;
        }
        else {
            items = this.items.filter(x => StringHelper.contains(x.name, term) || StringHelper.contains(x.label, term));
        }

        // tabindex
        let index = 1;

        // Build items
        if (items?.length) {
            for (let i = 0; i < items.length; i++) {
                html += `<div class="item ${(items[i].name == this.options.value?.eq) ? "item-selected" : ""}" tabindex="${index++}" onclick="component.select('${items[i].name}')">`;
                html += `    <div class="label">${Messages.get(items[i].label)}</div>`;
                html += `</div>`;
            }
        }

        // Assign new results
        this.dialog.querySelector("div.items").innerHTML = html;

        // Propagate component instance to all DOM child nodes
        this.dialog.querySelectorAll("div.items div.item").forEach((e: HTMLElement) => {
            (<any>e).component = this;
        });
    }

    public async render(): Promise<void> {
        // Create items
        await this.createItems();

        // Get select item
        if (this.options.value) {
            this.selected = this.items.find(x => x.name == this.options.value.eq);
        }

        // Draw
        super.render();

        // Build items by searching
        this.search(null);
    }

    public select(name: string): void {
        // Already selected?
        if (this.selected?.name == name) {
            return;
        }

        // Find item
        this.selected = this.items.find(x => x.name == name);

        // Hide dialog
        this.hide();

        // Set value based on item data or name
        this.setValue({
            eq: this.selected.data || this.selected.name
        });

        // OnSubmit handler
        this.onSubmit();
    }

    public clear(): void {
        // Reset selected
        this.selected = null;

        // Super call
        super.clear();
    }
}