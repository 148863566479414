import "./pinko-browser.scss";
import template from "./pinko-browser.hbs";
import { PagedData, PinkoBrowserOptions } from "./types.js";
import { component } from "@incinity/hiyo/decorators.js";
import { Log } from "@incinity/hiyo/log.js";
import { PinkoFilter } from "../pinko-filter/pinko-filter.js";
import { PinkoTable } from "../pinko-table/pinko-table.js";
import { PinkoPreview } from "../pinko-preview/pinko-preview.js";
import { PinkoDetail } from "../pinko-detail/pinko-detail.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { TableColumn, TableRow } from "../pinko-table/types.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoPaging } from "../pinko-paging/pinko-paging.js";

export const WIDTH_NAME = 200;
export const WIDTH_TITLE = 200;
export const WIDTH_NUMBER = 140;
export const WIDTH_PARTNER = 120;
export const WIDTH_PRICE = 120;
export const WIDTH_DATE_TIME = 180;

@component(template, true)
export abstract class PinkoBrowser<T extends Context = Context, U extends PinkoBrowserOptions = PinkoBrowserOptions> extends PinkoComponent<T, U> {

    // Properties
    public page: number = 1;

    // Components
    public filter: PinkoFilter;
    public table: PinkoTable;
    public paging: PinkoPaging;
    public preview: PinkoPreview;
    public detail: PinkoDetail;

    public onCreate() {
        // Create all components
        this.createFilter();
        this.createTable();
        this.createPaging();
        this.createPreview();
        this.createDetail();

        // Set all bindings
        this.bindAll();
    }

    public onDetach(): void {
        // Remove preview if connected
        this.preview?.remove();
    }

    public createFilter(): void {
        Log.w(`${this.constructor.name}.createFilter() not implemented. Forgot to override it?`);
    }

    public createTable(): void {
        Log.w(`${this.constructor.name}.createTable() not implemented. Forgot to override it?`);
    }

    public createPaging(): void {
        Log.w(`${this.constructor.name}.createPaging() not implemented. Forgot to override it?`);
    }

    public createPreview(): void {
        Log.w(`${this.constructor.name}.createPreview() not implemented. Forgot to override it?`);
    }

    public createDetail(): void {
        Log.w(`${this.constructor.name}.createDetail() not implemented. Forgot to override it?`);
    }

    public bindAll(): void {
        // Filter bindings
        if (this.filter) {
            // Open detail
            this.filter.onSelect = async (data) => {
                await this.showDetail(data);
            }

            // Reload table
            this.filter.onSearch = async (data: any) => {
                await this.load();
            }
        }

        // Table bindings
        if (this.table) {
            // Reload with new sorting
            this.table.onColumnSelect = async (column: TableColumn) => {
                await this.load();
            }

            // Open preview
            this.table.onRowSelect = async (row: TableRow) => {
                await this.openPreview(row.data);
            }

            // Open detail
            this.table.onRowOpen = async (row: TableRow) => {
                await this.showDetail(row.data);
            }

            // Load new data
            this.table.onMoreResults = async () => {
                // Next page
                this.page += 1;

                // Reload
                await this.load(true);
            }
        }

        // Preview bindings
        if (this.preview) {
            // Refresh self
            this.preview.onUpdate = async () => {
                await this.load();
            }

            // Deselect on detach
            this.preview.onDetach = () => {
                this.table.unselectRow(this.preview.options.data._id);
            }
        }

    }

    public async openPreview(data: any): Promise<void> {
        // Assign data
        this.preview.options.data = data;

        // Force to render from template
        this.preview.createdEmpty = true;

        // Reload data if already connected
        if (this.preview.isConnected) {
            await this.preview.reload();
        }
        // Attach if closed
        else {
            this.preview.appendTo(document.body.querySelector("aside"));
        }
    }

    public showDetail(data: any): any {
        // Assign data
        this.detail.options.data = data;

        // Force to reuse template
        this.detail.clear();

        // Show modal
        this.detail.showModal();
    }

    public async load(append?: boolean): Promise<void> {
        // Lock control
        this.lock();

        // Data from filter
        let data = this.filter.getData();

        // Create dataset search object
        let search = {
            pagination: {
                pageSize: this.options.pageSize || 0,
                page: this.page
            },
            filter: {
                ...data
            },
            sort: {}
        }

        // Url with host
        let url = new URL(this.options.url);

        // Add sort by selected column
        let column = this.table.options.columns.find(x => x.selected);
        if (column) {
            // Sort parameter
            search.sort[column.name] = column.descendent ? "desc" : "asc";

            // Second sort condition enabled?
            if (column.extraSort) {
                search.sort[column.extraSort] = "desc";
            }
        }

        console.info(search);

        // Get resources
        let resource: PagedData = await this.options.http.request("POST", url.toString(), {
            data: search
        });

        // Set summary with all count to filter
        this.filter.setSummary({
            ...resource.summary
        });

        // Set data to table
        this.table.setData(resource.data, append);

        // Redraw
        this.table.render();

        // Setup paging
        if (this.paging) {
            // Set summary
            this.paging.summarize(resource.data);

            // Redraw
            this.paging.render();
        }

        // Unlock control
        this.unlock();
    }
}