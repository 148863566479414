import "./invoicing-form.scss";
import template from "./invoicing-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoFormDialog } from "@incinity/pinko/components/pinko-form-dialog/pinko-form-dialog.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { InvoicingFormOptions } from "./types.js";
import { Invoicing, Payment } from "../../../clients/api-client/types.js";
import { ObjectHelper } from "@incinity/hiyo/object-helper.js";
import { PaymentForm } from "../../payments/payment-form/payment-form.js";

@component(template, true)
export class InvoicingForm extends PinkoFormDialog<IncinetContext, InvoicingFormOptions> {

    // Properties
    public invoicing: Invoicing;

    public onAttach(): void {
        // Set data or empty project to invoicing
        // When data available, we create a deep copy not to modify data passed by reference
        this.invoicing = ObjectHelper.copy(this.options.data) || {};
    }

    public addPayment(): void {
        // Payment dialog
        let dialog = new PaymentForm(this.context);

        // Handle data
        dialog.onSubmit = (result: Payment) => {
            // Add new address
            this.invoicing.payments ? this.invoicing.payments.push(result) : this.invoicing.payments = [result];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public editPayment(i: number): void {
        // Payment dialog
        let dialog = new PaymentForm(this.context, {
            data: {
                // Transform data to different form format
                ...this.invoicing.payments[i],
                amount: this.invoicing.payments[i].conversion?.amount || this.invoicing.payments[i].amount,
                currency: this.invoicing.payments[i].conversion?.currency || "CZK"
            }
        });

        // Handle data
        dialog.onSubmit = (result: Payment) => {
            // Assign edited address
            this.invoicing.payments[i] = result;

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removePayment(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove address by index
        this.invoicing.payments.splice(i, 1);

        // Redraw
        this.render();
    }

    public render(): any {
        // Calculate paid and total
        if (this.invoicing) {
            // Reset values
            this.invoicing.paid = 0;
            this.invoicing.total = 0;

            // Get sums from payments
            for (let payment of this.invoicing.payments) {
                this.invoicing.total += payment.amount;
                this.invoicing.paid += payment.issued ? payment.amount : 0;
            }
        }

        // Call render
        super.render();
    }

    public async trySubmit(): Promise<any> {
        // Merge invoicing data with form
        this.invoicing = {
            ...this.invoicing,
            ...this.form.getData(true)
        }

        // Update call?
        if (this.invoicing._id) {
            return await this.context.api.updateResource(`invoicing/${this.invoicing._id}`, this.invoicing);
        }
        // Create call
        else {
            return await this.context.api.createResource("invoicing", this.invoicing);
        }
    }

}