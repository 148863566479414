import "./offer-form.scss";
import template from "./offer-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Offer, OfferItem, Partner } from "../../../clients/api-client/types.js";
import { PinkoFormDialog } from "@incinity/pinko/components/pinko-form-dialog/pinko-form-dialog.js";
import { OfferModuleForm } from "../offer-module-form/offer-module-form.js";
import { OfferConnectorForm } from "../offer-connector-form/offer-connector-form.js";
import { OfferServiceForm } from "../offer-service-form/offer-service-form.js";
import { OfferDiscountForm } from "../offer-discount-form/offer-discount-form.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { OfferBrowser } from "../offer-browser/offer-browser.js";
import { PartnerSelect } from "../../partners/partner-select/partner-select.js";
import { DropdownItem } from "@incinity/pinko/components/pinko-dropdown/types.js";
import { OfferFormOptions } from "./types.js";
import { OfferPriceForm } from "../offer-price-form/offer-price-form.js";

@component(template, true)
export class OfferForm extends PinkoFormDialog<IncinetContext, OfferFormOptions> {

    // Properties
    public top: number;
    public offer: Offer;
    public partner: Partner;
    public modules: OfferItem[];
    public connectors: OfferItem[];
    public services: OfferItem[];

    public addModule(): void {
        // Module dialog
        let dialog = new OfferModuleForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeModule(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove module by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public addConnector(): void {
        // Connector dialog
        let dialog = new OfferConnectorForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeConnector(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove connector by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public addService(): void {
        // Service dialog
        let dialog = new OfferServiceForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeService(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove service by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public setDatasetCount(i: number, j: number, count: number): void {
        // Update count
        this.offer.items[i].datasources[j].count = Number(count);

        // Render
        this.render();
    }

    public setServiceCount(i: number, count: number): void {
        // Update count
        this.offer.items[i].count = Number(count);

        // Render
        this.render();
    }

    public editDiscount(): void {
        // Service dialog
        let dialog = new OfferDiscountForm(this.context, {
            data: {
                discount: this.offer.extraDiscount
            }
        });

        // Handle data
        dialog.onSubmit = (data: any) => {
            // Add new item
            this.offer.extraDiscount = Number(data.discount || 0);

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public editItem(i: number): void {
        // Service dialog
        let dialog = new OfferPriceForm(this.context, {
            data: {
                name: this.offer.items[i].name,
                price: this.offer.items[i].price
            }
        });

        // Handle data
        dialog.onSubmit = (data: any) => {
            // Update item
            this.offer.items[i].name = data.name;
            this.offer.items[i].price = Number(data.price || 0);

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public setScroll(top: number): void {
        // Save scroll top position to restore if content is rendered again
        this.top = top;
    }

    public render(): void {
        // Empty offer check
        this.offer = this.offer || <any>{};

        // Reset summary
        this.offer.summary = {
            licenses: 0,
            services: 0,
            maintenance: 0,
            discount: 0,
            total: 0,
            partnerTotal: 0
        }

        // Calculate prices
        for (let item of this.offer.items || []) {
            // Set order as index
            item.order = this.offer.items.indexOf(item);

            // Sum up extra item price
            item.extra = item.datasources?.map(x => x.price * x.count).reduce((a, b) => a + b, 0) || 0;

            // Add licenses
            if (item.type == "Module" || item.type == "Connector") {
                this.offer.summary.licenses += item.price + item.extra;
            }

            // Add services
            if (item.type == "Service" && item.name != "Maintenance") {
                this.offer.summary.services += (item.price * item.count) || 0;
            }

            // Add total
            if (item.name != "Maintenance") {
                this.offer.summary.total += ((item.price * item.count) || 0) + item.extra;
            }
        }

        // Find maintenance service if available
        let maintenance = this.offer.items?.find(x => x.name == "Maintenance");

        // Calculate maintenance, discount and total prices
        this.offer.summary.maintenance = (maintenance && !maintenance.price) ? this.offer.summary.licenses * (maintenance.count * 0.12) : (maintenance?.price || 0);
        this.offer.summary.total += this.offer.summary.maintenance;
        this.offer.summary.total -= (this.offer.extraDiscount || 0);
        this.offer.summary.discount = (this.offer.summary.total > 0) ? -this.offer.summary.total * ((this.partner?.discount || 0) / 100) : 0; // Avoid -0
        this.offer.summary.partnerTotal = this.offer.summary.total + this.offer.summary.discount;

        // Filter out helper properties
        this.modules = this.offer.items?.filter(x => x.type == "Module");
        this.connectors = this.offer.items?.filter(x => x.type == "Connector");
        this.services = this.offer.items?.filter(x => x.type == "Service");

        // Call render
        super.render();

        // Load partner discount when partner is selected
        this.querySelector<PartnerSelect>("partner-select").onSelect = async (item: DropdownItem)=> {
            // Set partner to have discount
            this.partner = await this.context.api.getResource(`/partners/${item.name}`);

            // Redraw
            this.render();
        }

        // Keep scroll top in same position
        this.querySelector("article").scrollTo({ top: this.top });
    }

    public async trySubmit(){
        // Merge offer data with form
        this.offer = {
            pricelist: this.context.data.pricelist.version,
            discount: this.partner?.discount || 0,
            ...this.offer,
            ...this.form.getData(true)
        }

        // Update call?
        if (this.offer._id) {
            await this.context.api.updateResource(`offers/${this.offer._id}`, this.offer);
        }
        // Create call
        else {
            await this.context.api.createResource("offers", {
                ...this.offer,
                draft: true
            });
        }
    }

    public async load(): Promise<void> {
        // Detail mode
        if (this.options.data?._id) {
            this.offer = await this.context.api.getResource(`offers/${this.options.data._id}`);
            this.partner = await this.context.api.getResource(`partners/${this.offer.partner._id}`);
        }

        // Copy mode?
        if (this.options.copy) {
            delete this.options.data._id;
            delete this.options.data.expiration;
            this.options.data.title = `${this.options.data.title} (1)`;
        }
    }
}