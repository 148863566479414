import "./pinko-table.scss";
import template from "./pinko-table.hbs";
import { component, events } from "@incinity/hiyo/decorators.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { PinkoTableOptions, TableAction, TableColumn, TableColumnType, TableGroup, TableRow } from "./types.js";
import { Log } from "@incinity/hiyo/log.js";
import { PropertyResolver } from "@incinity/hiyo/types.js";
import { ObjectHelper } from "@incinity/hiyo/object-helper.js";
import { DateHelper } from "@incinity/hiyo/date-helper.js";
import { NumberHelper } from "@incinity/hiyo/number-helper.js";
import { PinkoDropdown } from "../pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { Router } from "@incinity/hiyo/router.js";

@component(template)
@events("keydown")
export class PinkoTable<T extends Context = Context> extends PinkoComponent<T, PinkoTableOptions> {

    // Properties
    public selectedRow: TableRow;
    public selectedRows: { [id: string]: TableRow };
    public groups: TableGroup[];
    public menu: PinkoDropdown;

    // Event handling methods
    public onColumnSelect(column: TableColumn): void {}
    public onRowSelect(row: TableRow): void {}
    public onRowOpen(row: TableRow): void {}
    public onRowMultiselect(rows: TableRow[]): void {}
    public onMoreResults(): void {}
    public onActionSelect(row: TableRow, action: TableAction): void {}

    public onCreate() {
        // Validation of empty columns
        if (!this.options.columns || this.options.columns.length == 0) {
            Log.w(`${this.name} has no columns defined, will be displayed as empty. Ensure you created component options correctly.`);
        }

        // Empty selected rows
        this.selectedRows = {};
    }

    public onEvent(event: Event) {
        // Keyboard?
        if (event instanceof KeyboardEvent) {
            // Arrow up?
            if (event.key == "ArrowUp") {
                if (this.selectedRow && this.options.data && this.selectedRow.index > 0) {
                    // Move to next row
                    this.selectRow(this.options.data[this.selectedRow.index - 1][this.options.rows.id]);

                    // Prevent to not scroll up
                    event.stopPropagation();
                    event.preventDefault();
                }
            }

            // Arrow down?
            if (event.key == "ArrowDown") {
                if (this.selectedRow && this.options.data && this.selectedRow.index < this.options.data.length - 1) {
                    // Move to next row
                    this.selectRow(this.options.data[this.selectedRow.index + 1][this.options.rows.id]);

                    // Prevent to not scroll down
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        }
    }

    public onRender(): void {
        // Perform selection (if row exists after update)
        if (this.selectedRow) {
            this.querySelector(`table tbody tr[id='${this.selectedRow.id}']`)?.classList.add("row-selected");
        }
    }

    public render() {
        // Rebuild rows
        this.createRows();

        // Call component render
        super.render();
    }

    public findRow(id: string): TableRow {
        // Iterate groups to find row by id
        for (let group of this.groups) {
            let row = group.rows.find(x => x.id == id);
            if (row) {
                return row;
            }
        }

        // No round found
        return null;
    }

    public selectColumn(i: number): void {
        if (i < 0 || i >= this.options.columns.length) {
            Log.w(`Column index of ${this.name} out of range`);
            return;
        }

        const column = this.options.columns[i];

        // Same column, will change sorting order
        if (column.selected) {
            column.descendent = !column.descendent;
        }
        // Another column
        else {
            // Deselect all
            this.options.columns.forEach(x => x.selected = false);

            // Select current
            column.selected = true;
        }

        // We have to deselect row
        // FIXME: This should not happening, we have to store get new index after rows are sorted
        //this.selectedRow = undefined;

        // Sort in client?
        if (this.options.autosort) {
            // Redraw
            this.render();
        }

        // OnColumnSelect handler
        this.onColumnSelect(column);
    }

    public unselectRow(id: string): void {
        // Not selected
        if (this.selectedRow?.id != id) {
            return;
        }

        // Unselect
        this.querySelector(`table tbody tr[id='${this.selectedRow.id}']`)?.classList.remove("row-selected");

        // Remove from selected
        this.selectedRow = null;
    }

    public selectRow(id: string, skipHistory?: boolean): void {
        // Already selected?
        if (this.selectedRow?.id == id) {
            return;
        }

        // New row to select
        let row = this.findRow(id);

        // Not found?
        if (!row) {
            Log.w(`Could not select row ${id || "with empty id"} in ${this.name}`);
            return;
        }

        if (this.selectedRow) {
            this.unselectRow(this.selectedRow.id);
        }

        // Single select type
        if (this.options.type == "SingleSelect") {
            // Select current row
            this.querySelector(`table tbody tr[id='${id}']`)?.classList.add("row-selected");

            // Store index
            this.selectedRow = row;
        }

        // Push route to history
        if (!skipHistory && this.options.rows.route) {
            Router.pushHistory(this.options.rows.route, row);
        }

        // OnRowSelect handler
        this.onRowSelect(row);
    }

    public openRow(id: string): void {
        // New row to open
        let row = this.findRow(id);

        // Not found?
        if (!row) {
            Log.w(`Could not open row ${id || "with empty id"} in ${this.name}`);
            return;
        }

        // OnRowSelect handler
        this.onRowOpen(row);
    }

    public selectAction(id: string, name: string, skipHistory?: boolean) {
        // Hovered row
        let row = this.findRow(id);

        // Find action
        let action = this.options.actions.find(x => x.name == name);

        // Stop event propagation to prevent row click
        event?.stopPropagation();

        // Push route to history
        if (!skipHistory && action.route) {
            Router.pushHistory(action.route, row);
        }

        // OnActionSelect handler
        this.onActionSelect(row, action);
    }

    public selectMenu(id: string) {
        // Hovered row
        let row = this.findRow(id);

        // Stop event propagation to prevent row click
        event.stopPropagation();

        // Select row to identify for whom the men is
        this.querySelector(`table tbody tr[id='${id}']`).classList.add("row-hover");

        // Close menu if previously opened
        this.menu?.remove();

        // Create items
        let items: DropdownItem[] = [];

        // Build items from actions
        for (let action of this.options.actions) {
            // Add to action to item
            items.push({
                name: action.name,
                label: action.label,
                icon: action.icon,
                escalated: action.escalated,
                disabled: (typeof action.disabled == "boolean" && action.disabled) || (typeof action.disabled == "function" && action.disabled(row.data))
            });
        }

        this.menu = new PinkoDropdown(this.context, {
            anchor: "TopRight",
            start: "BottomRight",
            offset: [0, 8],
            groups: [
                {
                    name: "Main",
                    items: items
                }
            ]
        })

        // Remove hover when menu disappear
        this.menu.onDetach = () => {
            this.querySelector(`table tbody tr[id='${id}']`).classList.remove("row-hover");
        }

        // Select menu
        this.menu.onSelect = (item: DropdownItem) => {
            this.onActionSelect(row, this.options.actions.find(x => x.name == item.name));
        }

        // Show dropdown
        this.menu.show(this.querySelector(`table tbody tr[id='${id}'] div.icon-menu`));
    }

    public selectCheckbox(rowId: string, e: MouseEvent): void {
        // Row not checked?
        if (!this.selectedRows[rowId]) {
            // First find row data
            let row: TableRow = null;

            for (let group of this.groups) {
                row = group.rows.find(x => x.id == rowId);
                if (row) {
                    break;
                }
            }

            // Select current checkbox and row
            this.querySelector(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-off", "checkbox-on");
            this.querySelector(`table tbody tr[id='${rowId}']`)?.classList.add("row-selected");

            // Add row to selection
            this.selectedRows[rowId] = row;
        }
        // Checked already?
        else {
            // Unselect current checkbox and row
            this.querySelector(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-on", "checkbox-off");
            this.querySelector(`table tbody tr[id='${rowId}']`)?.classList.remove("row-selected");

            // Remove row from selection
            delete this.selectedRows[rowId];
        }

        // Stop event propagation to prevent row click
        e.stopPropagation();

        // OnMultiRowSelect handler
        this.onRowMultiselect(Object.values(this.selectedRows));

    }

    public unselectCheckboxes(): void {
        // Unselect all
        for (let rowId in this.selectedRows) {
            // Unselect current checkbox and row
            this.querySelector(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-on", "checkbox-off");
            this.querySelector(`table tbody tr[id='${rowId}']`)?.classList.remove("row-selected");

            // Remove row from selection
            delete this.selectedRows[rowId];
        }
    }

    public selectMore(): void {
        // OnMoreResults handler
        this.onMoreResults();
    }

    public setData(data: any[], append?: boolean, end?: boolean): void {
        // Will we append data to existing (scrolling mode?)
        if (append && this.options.data) {
            this.options.data.push(...data);
        }
        // Just rewrite data
        else {
            this.options.data = data;
        }
    }

    private sortData(type: TableColumnType, property: string | PropertyResolver, descendent?: boolean): void {
        // Sort via custom compare function
        this.options.data.sort((a: any, b: any): number => {
            // Null combinations
            if (!a && !b) return 0;
            if (a && !b) return 1;
            if (!a && b) return -1;

            // Get values
            let valueA = ObjectHelper.getProperty(a, property);
            let valueB = ObjectHelper.getProperty(b, property);

            // Result
            let result = 0;

            switch (type) {
                case "String":
                    result = String(valueA).localeCompare(String(valueB));
                    break;
                case "Date":
                case "Time":
                case "DateTime":
                    result = new Date(valueA).getTime() - new Date(valueB).getTime();
                    break;
                case "Duration":
                case "Number":
                    result = Number(valueA) - Number(valueB);
                    break;
            }

            if (descendent) {
                result *= -1;
            }

            return result;
        });
    }

    private createRows(): void {
        // Empty groups
        this.groups = [];

        // Empty rows
        let rows = [];

        // No data?
        if (!this.options.data) {
            return;
        }

        // Sort in client?
        if (this.options.autosort) {
            // Sort based on selected column
            let column = this.options.columns.find(x => x.selected);
            if (column) {
                this.sortData(column.type, column.property, column.descendent);
            }
        }

        // Create rows based on the data and column definitions
        for (let i in this.options.data) {
            let d = this.options.data[i];

            // Get row id, if no row id found, index will be used instead
            let id = ObjectHelper.getProperty(d, this.options.rows.id) ?? i;

            let decorator = null;

            // Row has own decorator?
            if (typeof this.options.rows.decorator == "function") {
                decorator = this.options.rows.decorator(d);
            }

            let disabler = null;

            // Row has own disabler?
            if (typeof this.options.rows.disabler == "function") {
                disabler = this.options.rows.disabler(d);
            }

            // New row with unique id and empty cells
            let row: TableRow = {
                id: id,
                index: Number(i),
                data: d,
                cells: [],
                decorator: decorator,
                disabler: disabler
            };

            for (let c of this.options.columns) {
                // Get data value
                let value = ObjectHelper.getProperty(d, c.property);

                // Undefined or null value?
                if (value == null && !c.formatter) {
                    // We are putting empty string as we need to render <td/> tag regardless there is content or no
                    row.cells.push("");
                }
                // Custom formatter that is also able to handle null values
                else if (c.formatter) {
                    row.cells.push(c.formatter(value, d) || "");
                }
                // Format based on type
                else {
                    switch (c.type) {
                        case "String":
                            row.cells.push(String(value));
                            break;
                        case "Date":
                            row.cells.push(DateHelper.toDateString(value));
                            break;
                        case "Time":
                            row.cells.push(DateHelper.toTimeString(value));
                            break;
                        case "DateTime":
                            row.cells.push(DateHelper.toShortDateTimeString(value));
                            break;
                        case "Duration":
                            row.cells.push(DateHelper.toDuration(value));
                            break;
                        case "Number":
                            row.cells.push(NumberHelper.toNumber(value));
                            break;
                        default:
                            row.cells.push(value);
                            break;
                    }
                }
            }

            rows.push(row);
        }

        // Grouping available?
        if (this.options.groups) {
            // Group rows
            for (let row of rows) {
                let value = ObjectHelper.getProperty(row.data, this.options.groups.property);

                // No value
                if (!value) {
                    continue;
                }

                // Has formatter?
                if (this.options.groups.formatter) {
                    value = this.options.groups.formatter(value, row.data) || "";
                }

                // Find relevant group
                let group = this.groups.find(x => x.value == value);

                // Exists?
                if (group) {
                    group.rows.push(row);
                }
                else {
                    // Create new group
                    this.groups.push({
                        value: value,
                        rows: Array.of(row)
                    });
                }
            }

            // Group sorting enabled?
            if (this.options.groups.sort) {
                this.groups.sort(this.options.groups.sort);
            }
        }
        else {
            // Single group with all rows and only if we have data
            if (rows.length > 0) {
                this.groups.push({
                    value: null,
                    rows: rows
                });
            }
        }
    }

    public exportCsv(name: string): void {
        // No data
        if (!this.groups) {
            Log.w("No data to export");
        }

        // Export file
        let csv = "";

        // Columns first
        for (let i = 0; i < this.options.columns.length; i++) {
            // Fake column?
            if (this.options.columns[i].property == null) {
                continue;
            }

            // Add column name
            csv += this.options.columns[i].name;

            // Separator?
            if (i < this.options.columns.length - 1) {
                csv += ";";
            }
        }

        // New line
        csv += "\n";

        for (let d of this.options.data) {
            for (let i = 0; i < this.options.columns.length; i++) {
                // Fake column?
                if (this.options.columns[i].property == null) {
                    continue;
                }

                // Get value from data
                let value = ObjectHelper.getProperty(d, this.options.columns[i].property);

                // Add to export
                csv += value ?? "";

                // Separator?
                if (i < this.options.columns.length - 1) {
                    csv += ";";
                }
            }

            // New line
            csv += "\n";
        }

        // Create link to download CVS on background
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);

        // Download
        alert('TODO');
        //Dom.openLink(url, `export-${Date.now()}.csv`)
    }

}