import "./pinko-input.scss";
import template from "./pinko-input.hbs";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoInputOptions } from "./types.js";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

@component(template)
export class PinkoInput extends PinkoField<Context, PinkoInputOptions> {

    // Event handling methods
    public onKey(key: number): void {};

    public read(): void {
        // Read value
        this.options.value = this.querySelector<HTMLInputElement>((this.options.format == "Text") ? "textarea" : "input").value;

        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Number format?
        if (this.options.value != null && this.options.format == "Number") {
            this.options.value = Number(this.options.value);
        }
    }

    public change() {
        // Assign value to options
        this.read();
    }

    public focus(): void {
        // Move label on top
        this.querySelector("label[placeholder=true]")?.classList.toggle("top", true);

        // Super call
        super.focus();
    }

    public blur(): void {
        // Value is still empty
        if (this.options.value == null) {
            // Center label
            this.querySelector("label[placeholder=true]")?.classList.toggle("top", false);
        }
        // Validate if not empty
        else {
            this.validate();
        }

        // Super call
        super.blur();
    }

    public key(key: number): void {
        // Assign value to options
        this.read();

        // Enter
        if (key == 13) {
            if (this.options.autosubmit) {
                this.submit();
            }
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear value
            this.setValue(null);
            return;
        }

        // OnKey handler
        this.onKey(key);
    }

}