import "./pinko-picker.scss";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoPickerOptions } from "./types.js";
import { query, events, component } from "@incinity/hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

export const SHOW_TIMER = 10;

@component()
@events("click", "keydown")
export abstract class PinkoPicker<T extends Context = Context, U extends PinkoPickerOptions = PinkoPickerOptions> extends PinkoField<T, U> {

    // Child components
    @query("div.dialog") public dialog: HTMLElement;

    public onEvent(event: Event): void {
        // Clicked outside of dialog hides self
        if (event instanceof MouseEvent) {
            this.hide();
        }

        // Esc key hides self
        if (event instanceof KeyboardEvent && event.key == "Escape") {
            this.hide();
        }
    }

    public render(): void {
        // Super call
        super.render();

        // Prevent of hiding self
        this.dialog?.addEventListener("click", (event: MouseEvent) => {
            event.stopPropagation();
        });
    }

    public toggle(show: boolean): void {
        // Use class to toggle dialog on/off
        this.dialog.classList.toggle("dialog-visible", show);
    }

    public show(): void {
        // Because click handler hides dialog back immediately, we need to delay it for a while
        setTimeout(() => {
            this.toggle(true);

            // Autofocus of first field
            this.dialog.querySelector<HTMLInputElement>("input")?.focus();
        }, SHOW_TIMER);
    }

    public hide(): void {
        // Nothing to hide?
        if (!this.dialog) {
            return;
        }

        // Hide dialog
        this.toggle(false);
    }

    public clear(): void {
        // Stop propagation
        event.stopPropagation();

        // Nothing to clear?
        if (this.options.value == null) {
            return;
        }

        // Clear value
        this.setValue(null);

        // OnSubmit handler
        this.onSubmit();
    }

}