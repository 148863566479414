import { Router } from "@incinity/hiyo/router.js";
import { RouteHandler } from "@incinity/hiyo/types.js";
import { OfferBrowser } from "../components/offers/offer-browser/offer-browser.js";
import { PinkoMenu } from "@incinity/pinko/components/pinko-menu/pinko-menu";

export class IncinetRouter extends Router {

    public createRoutes() {
        this.addRoute("/partners", this.openPartners);
        this.addRoute("/partners/:id", this.openPartnersPreview);
        this.addRoute("/partners/:id/detail", this.openPartnersDetail);
        this.addRoute("/offers", this.openOffers);
        this.addRoute("/offers/:id", this.openOffersPreview);
        this.addRoute("/offers/:id/detail", this.openOffersDetail);
        this.addRoute("/offers/:id/download", this.openOffersDownload);
    }

    public openPartners: RouteHandler = (match: any): void => {
        this.query<PinkoMenu>("pinko-menu")?.selectItem("PartnerBrowser", true);
    }

    public openPartnersPreview: RouteHandler = async (match: any): Promise<void> => {
        // Open partners browser and preview
        this.openPartners();
        await this.wait(200);
        this.query<OfferBrowser>("partner-browser")?.table.selectRow(match.id, true);
    }

    public openPartnersDetail: RouteHandler = async (match: any): Promise<void> => {
        // Open partners browser and detail
        this.openPartners();
        await this.wait(200);
        this.query<OfferBrowser>("partner-browser")?.table.openRow(match.id);
    }

    public openOffers: RouteHandler = (match: any): void => {
        this.query<PinkoMenu>("pinko-menu")?.selectItem("OfferBrowser", true);
    }

    public openOffersPreview: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and preview
        this.openOffers();
        await this.wait(200);
        this.query<OfferBrowser>("offer-browser")?.table.selectRow(match.id, true);
    }

    public openOffersDetail: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and detail
        this.openOffers();
        await this.wait(200);
        this.query<OfferBrowser>("offer-browser")?.table.openRow(match.id);
    }

    public openOffersDownload: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and detail
        this.openOffers();
        await this.wait(200);
        this.query<OfferBrowser>("offer-browser")?.table.selectAction(match.id, "Download", true);
    }

}