import "./offer-browser.scss";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoFilter } from "@incinity/pinko/components/pinko-filter/pinko-filter.js";
import { SearchItem } from "@incinity/pinko/components/pinko-search/types.js";
import { PinkoTable } from "@incinity/pinko/components/pinko-table/pinko-table.js";
import { OfferPreview } from "../offer-preview/offer-preview.js";
import { TableAction, TableRow } from "@incinity/pinko/components/pinko-table/types.js";
import { FilterItem } from "@incinity/pinko/components/pinko-filter/types.js";
import { OfferForm } from "../offer-form/offer-form.js";
import { PinkoConfirm } from "@incinity/pinko/components/pinko-confirm/pinko-confirm.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoBrowser, WIDTH_NAME, WIDTH_NUMBER, WIDTH_PRICE, WIDTH_TITLE } from "@incinity/pinko/components/pinko-browser/pinko-browser.js";
import { NumberHelper } from "@incinity/hiyo/number-helper.js";
import { PinkoPaging } from "@incinity/pinko/components/pinko-paging/pinko-paging.js";
import { TemplatePreview } from "../../common/template-preview/template-preview.js";
import Handlebars from "handlebars";
import { DomHelper } from "@incinity/hiyo/dom-helper.js";
import { Offer } from "../../../clients/api-client/types.js";
import { PricePicker } from "../../common/price-picker/price-picker.js";
import { UserPicker } from "../../common/user-picker/user-picker.js";
import { PartnerPicker } from "../../partners/partner-picker/partner-picker.js";
import { PinkoStarPicker } from "../../../@incinity/pinko/components/pinko-star-picker/pinko-star-picker.js";
import { SystemHelper } from "@incinity/hiyo/system-helper.js";

const STATUS_ICON = {
    "Draft": "Draft",
    "Sent": "Send",
    "Lost": "ThumbsDown",
    "Won": "ThumbsUp",
}

@component()
export class OfferBrowser extends PinkoBrowser<IncinetContext> {

    public onAttach(): void {
        // Set options before render and with already passed context
        this.options = {
            http: this.context.api.http,
            url: `${this.context.api.options.host}/api/datasets/offers`
        }
    }

    public createFilter() {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "components.OfferBrowser.title",
            items: [
                {
                    name: "Add",
                    icon: "Add",
                },
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            fields: [
                new PinkoStarPicker(this.context, {
                    name: "users.starred",
                    placeholder: "Starred"
                }),
                new PricePicker(this.context, {
                    name: "summary.total",
                    placeholder: "Price"
                }),
                new PartnerPicker(this.context, {
                    name: "partner._id",
                    placeholder: "Partner",
                    searchable: true
                }),
                new UserPicker(this.context, {
                    name: "created.user._id",
                    placeholder: "Created by",
                    searchable: true
                })
            ],
            tabs: [
                {
                    name: "All",
                    label: "All",
                    summary: "all",
                    selected: true
                },
                {
                    name: "Draft",
                    label: "Draft",
                    summary: "draft",
                    data: {
                        draft: {
                            eq: true
                        }
                    }
                },
                {
                    name: "Sent",
                    label: "Sent",
                    summary: "sent",
                    data: {
                        status: {
                            eq: "Sent"
                        }
                    }
                },
                {
                    name: "Lost",
                    label: "Lost",
                    summary: "lost",
                    data: {
                        status: {
                            eq: "Lost"
                        }
                    }
                },
                {
                    name: "Won",
                    label: "Won",
                    summary: "won",
                    data: {
                        status: {
                            eq: "Won"
                        }
                    }
                }
            ],
            resolver: async (term: string): Promise<SearchItem[]> => {
                // Search offers
                let offers = await this.context.api.createResource(`search/offers`, {
                    pagination: {
                        pageSize: 10,
                        page: 1
                    },
                    filter: {
                        search: term
                    },
                    sort: {
                        title: "asc"
                    }
                });

                // Return as items
                return offers.data?.map(x => ({
                    name: x.title,
                    label: `${x.number} ${x.title} ${x.partner.name}`.replace(new RegExp(`(${term})`, "gi"), "<strong>$1</strong>"),
                    data: x
                }));
            }
        });

        // Item selected
        this.filter.onItemSelect = async (item: FilterItem) => {
            // Reload?
            if (item.name == "Reload") {
                await this.load();
            }

            // Add?
            if (item.name == "Add") {
                this.showForm();
            }
        }
    }

    public createTable() {
        // Create table
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Normal",
            height: "100%",
            rows: {
                id: "_id",
                route: "/offers/:id",
                decorator: (data: any): string => {
                    // Disabled?
                    if (data.disabled) {
                        return "disabled";
                    }
                    // Expired?
                    else if (new Date(data.expiration).getTime() < Date.now()) {
                        return "error";
                    }
                    // Just default
                    else {
                        return null;
                    }
                }
            },
            columns: [
                {
                    name: "number",
                    type: "String",
                    property: "number",
                    label: "columns.number",
                    formatter: (value: any, data: any): string => {
                        return `
                            <div class="panel">
                                <div class="${data.users?.some(x => x._id == this.context.user.id && x.starred) ? "star star-selected" : "star"} star-${data?._id}" onclick="component.parentComponent.toggleStarred('${data?._id}')"></div>
                                <pinko-symbol icon="${data?.draft ? "Draft" : STATUS_ICON[data?.status]}" label="${data?.number}"></pinko-symbol>
                           </div>`
                    },
                    width: 164,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "title",
                    type: "String",
                    property: "title",
                    label: "columns.title",
                    width: 380,
                    ellipsis: true,
                    sortable: true,
                    selected: true
                },
                {
                    name: "summary.partnerTotal",
                    type: "Number",
                    property: "summary.partnerTotal",
                    label: "columns.price",
                    unit: "EUR",
                    width: WIDTH_PRICE,
                    align: "Right",
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "partner.name",
                    type: "String",
                    property: "partner.name",
                    label: "columns.partner",
                    width: WIDTH_NAME,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "created.user.name",
                    type: "String",
                    property: "created.user.name",
                    label: "columns.createdBy",
                    width: 180,
                    sortable: true
                },
                {
                    name: "created.timestamp",
                    type: "String",
                    property: (data: any) => {
                        return Math.round((Date.now() - new Date(data.created.timestamp).getTime()) / 86400000)
                    },
                    label: "columns.age",
                    unit: "days",
                    minWidth: 180,
                    sortable: true
                },
                /*{
                    name: "users",
                    type: "String",
                    property: "users",
                    label: "columns.users",
                    formatter: (value: string, data: any): string => {
                        let html = `<div class="cell cell-flex">`;
                        data.users.forEach((user: any, i: number) => {
                            html += `<pinko-acronym name="${user.name}" type="User" ${i > 0 ? `style="margin-left: -10px"` : ""}></pinko-acronym>`;
                        });
                        html += `</div>`;
                        return html;
                    },
                    minWidth: 180,
                    sortable: true
                },*/
            ],
            actions: [
                {
                    name: "Copy",
                    icon: "Copy",
                    label: "labels.copy",
                    tooltip: "Copy offer",
                    quick: true
                },
                {
                    name: "Download",
                    icon: "Download",
                    label: "labels.download",
                    tooltip: "Download PDF",
                    route: "/offers/:id/download",
                    quick: true
                },
                {
                    name: "Edit",
                    icon: "Edit",
                    label: "labels.edit",
                    route: "/offers/:id/edit"
                },
                {
                    name: "Print",
                    icon: "Print",
                    label: "labels.print",
                    route: "/offers/:id/print",
                },
                {
                    name: "Delete",
                    icon: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // Action selected
        this.table.onActionSelect = async (row: TableRow, action: TableAction) => {
            // Copy?
            if (action.name == "Copy") {
                this.showForm(row.data, true);
            }

            // Download?
            if (action.name == "Download") {
                await this.exportPdf(row.data);
            }

            // Edit?
            if (action.name == "Edit") {
                this.showForm(row.data);
            }

            // Detail?
            if (action.name == "Detail") {
                this.showDetail(row.data);
            }

            // Print?
            if (action.name == "Print") {
                await this.showPrint(row.data);
            }

            // Delete?
            if (action.name == "Delete") {
                this.showDelete(row.data);
            }
        }
    }

    public createPaging(): void {
        // Create paging
        this.paging = new PinkoPaging(this.context, {
            summarizer: (data: any[]) => {
                // Get sum
                let sum = data?.length ? data.map(x => x.summary.partnerTotal).reduce((a, b) => a + b, 0) : 0;

                // Return sum in EUR
                return `Total of ${NumberHelper.toNumber(sum)} EUR`;
            }
        });
    }

    public createPreview(): void {
        // Create preview
        this.preview = new OfferPreview(this.context);
    }

    /*public createDetail() {
        // Create detail
        this.detail = new OfferDetail(this.context);
    }*/

    public showDelete(data: Offer): void {
        // Create confirm dialog
        let confirm = new PinkoConfirm(this.context, {
            title: "Really delete?",
            text: "Are you sure you want to delete?",
            labelConfirm: "labels.delete",
            escalated: true
        });

        // Delete on confirm
        confirm.onConfirm = async () => {
            // Lock component
            this.lock();

            // API call
            await this.context.api.deleteResource(`offers/${data._id}`);

            // Unlock component
            this.unlock();

            // Close preview if open
            if (this.preview?.options?.data?._id == data._id) {
                this.preview.remove();
            }

            // Reload self
            await this.load();
        }

        // Show modal
        confirm.showModal();
    }

    public showDetail(data: Offer): void {
        // Offers have no detail, so we need to overwrite showDetail() to show form
        this.showForm(data);
    }

    public showForm(data?: Offer, copy?: boolean): void {
        // Create dialog
        let form = new OfferForm(this.context, {
            data: data,
            copy: copy
        });

        // Reload table on submit
        form.onSubmit = async () => {
            // Reload self
            await this.load();

            // Reload preview
            if (this.preview.isConnected && this.preview?.options.data?._id == data?._id) {
                await this.preview.reload();
            }
        }

        // Show modal
        form.showModal();
    }

    public async showPrint(data: Offer): Promise<void> {
        // Load data for full detail
        let offer = await this.context.api.getResource(`offers/${data._id}`);

        // Create preview dialog
        let preview = new TemplatePreview(this.context, {
            key: "OfferPdf",
            data: offer
        });

        // Show
        preview.showModal();
    }

    public async exportPdf(data: Offer): Promise<void> {
        // Load template
        let template = await this.context.api.getResource(`templates/OfferPdf`);

        // Get HTML from template
        let html = Handlebars.compile(template.content)(data);

        // Create file name
        let filename = `${data.number}.pdf`;

        // Call PDF export
        let result = await this.context.api.createResource("exports/pdf", {
            filename: filename,
            content: html
        });

        // Download file
        DomHelper.download(filename, result);
    }

    public async toggleStarred(id: string): Promise<void> {
        // Prevent row select
        event.stopPropagation();

        // Get star element
        let star = this.table.querySelector(`div.star-${id}`);

        // Toggle selected state
        let starred = star.classList.toggle("star-selected");
        let filtered = this.filter.getData()["users.starred"];

        // If unselected wait for better user experience
        if (!starred && filtered) {
            await SystemHelper.sleep(300);
        }

        // Update starred flag ad the background
        // We will not lock/unlock screen or render whole list to avoid flickering
        await this.context.api.updateResource(`offers/${id}/starred`, {
            starred: star.classList.contains("star-selected")
        });

        // If unselected wait for better user experience
        if (!starred && filtered) {
            await this.load();
        }

    }
}