import "./incinet-app.scss";
import template from "./incinet-app.hbs";
import { component, events } from "@incinity/hiyo/decorators.js";
import { Component } from "@incinity/hiyo/component.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { User } from "../../../clients/api-client/types.js";
import { Log } from "@incinity/hiyo/log.js";
import { IncinetRouter } from "../../../router/incinet-router.js";
import { PinkoMenu } from "@incinity/pinko/components/pinko-menu/pinko-menu.js";
import { MenuItem } from "@incinity/pinko/components/pinko-menu/types.js";
import { StringHelper } from "@incinity/hiyo/string-helper.js";
import { DropdownItem } from "@incinity/pinko/components/pinko-dropdown/types.js";
import { PinkoFormDialog } from "@incinity/pinko/components/pinko-form-dialog/pinko-form-dialog.js";
import { OfferForm } from "../../offers/offer-form/offer-form.js";
import { PartnerForm } from "../../partners/partner-form/partner-form.js";
import { MathHelper } from "@incinity/hiyo/math-helper.js";
import confetti from "canvas-confetti";

export const USER_TOKEN_KEY = "Hiyo.userToken";

@component(template, true)
@events("click")
export class IncinetApp extends Component<IncinetContext> {

    // Components
    public menu: PinkoMenu;

    public async onCreate() {
        Log.i("Welcome to Incinet. The world of fantasy🤡");

        // Create menu
        this.createMenu();
    }

    public async onEvent(event: MouseEvent) {
        // Triple click
        if (event.detail == 3) {
            let x = event.pageX / window.innerWidth;
            let y = event.pageY / window.innerHeight;

            // Fire!
            await confetti({
                //shapes: [confetti.shapeFromText({ text: "💵"})],
                angle: MathHelper.random(55, 125),
                spread: MathHelper.random(70, 120),
                particleCount: 200,
                origin: {
                    x: x,
                    y: y
                }
            });
        }
    }

    public async onRender(): Promise<void> {
        //await SystemHelper.sleep(200);

        // Fade in
        this.style.opacity = "1";

        // Start project router
        new IncinetRouter().start();
    }

    public createMenu(): void {
        // Create menu component
        this.menu = new PinkoMenu(this.context, {
            logo: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><g clip-path="url(#clip0_3081_35429)"><path d="M0 32H13.2277V23.584H11.0731V29.8453H2.15467V20.9269H32V18.7712H0V31.9989V32ZM11.0731 16.0299H13.2277V2.15467H32V0H11.0731V16.0299ZM17.28 4.90453V16.0235H19.2715V4.90453H17.28ZM31.9979 16.3819V4.90453H30.0064V11.6181L22.7925 4.54507V16.0224H24.784V9.3088L31.9979 16.3819Z" fill="#F00544"></path></g><defs><clipPath id="clip0_3081_35429"><rect width="32" height="32" fill="white"></rect></clipPath></defs></svg>`,
            items: [
                {
                    name: "HomeOverview",
                    icon: "Home",
                    label: "Home",
                    route: "/home"
                },
                {
                    name: "PartnerBrowser",
                    icon: "Partners",
                    label: "Partners",
                    route: "/partners"
                },
                {
                    name: "OfferBrowser",
                    icon: "Offer",
                    label: "Offers",
                    route: "/offers"
                },
                {
                    name: "InvoicingBrowser",
                    icon: "Invoicing",
                    label: "Invoicing",
                    route: "/invoicing"
                },
                {
                    name: "DeliveryBrowser",
                    icon: "Delivery",
                    label: "Delivery",
                    route: "/delivery"
                },
                {
                    name: "TaskBrowser",
                    icon: "Tasks",
                    label: "Tasks",
                    route: "/tasks"
                },
                {
                    name: "UserBrowser",
                    icon: "Users",
                    label: "Users",
                    route: "/users"
                },
                {
                    name: "SettingsPanel",
                    icon: "Settings",
                    label: "Settings",
                    route: "/settings"
                }
            ],
            actions: [
                {
                    name: "NewPartner",
                    icon: "Add",
                    label: "components.MainMenu.newPartner"
                },
                {
                    name: "NewOffer",
                    icon: "Add",
                    label: "components.MainMenu.newOffer"
                }
            ]
        });

        // Main menu select
        this.menu.onMenuSelect = (item: MenuItem) => {
            // Get component name
            let component = StringHelper.toKebabCase(item.name);

            // Open selected component (Home -> HomeBrowser -> <home-browser>)
            this.querySelector("main").innerHTML = `<${component}></${component}>`;
        }

        // Action select
        this.menu.onActionSelect = (item: DropdownItem) => {
            // Dialog base
            let dialog: PinkoFormDialog;

            // Dialog switch
            switch (item.name) {
                default:
                case "NewOffer":
                    dialog = new OfferForm(this.context);
                    break;
                case "NewPartner":
                    dialog = new PartnerForm(this.context);
                    break;
            }

            // Reload components that might display newly created record
            dialog.onSubmit = async () => {
                //await document.querySelector<OfferBrowser>("offer-browser")?.load();
            }

            // Show modal
            dialog.showModal();
        }

        // User logout
        this.menu.onUserLogout = async () => {
            await this.logout();
        }
    }

    public async login(user: User): Promise<void> {
        Log.i(`${this.constructor.name}: Received a "login" event, will proceed to enter the app`);

        // Assign user
        this.context.user = user;

        // Store user token to local storage
        localStorage.setItem(USER_TOKEN_KEY, user.token);

        // Set store token
        this.context.api.enableBasicAuthorization(user.token);

        // Synchronize data
        await this.feed();

        // Redraw
        this.render();
    }

    public async logout(): Promise<void> {
        // Set no user
        this.context.user = null;

        // Remove stored user token
        localStorage.removeItem(USER_TOKEN_KEY);

        // Server logout
        await this.context.api.logoutUser();

        // Redraw
        this.render();
    }

    public async feed(): Promise<void> {
        // Load external data after login
        this.context.data.pricelist = await this.context.api.getResource(`pricelists/current`);
        this.context.data.conversion = (await this.context.api.getResource(`config?key=conversion`))?.conversion;
    }

    public async load(): Promise<any> {
        // Get user token stored in local storage
        let token = localStorage.getItem(USER_TOKEN_KEY);

        // Token found, so we need to verify its validity
        if (token) {
            // Set store token
            this.context.api.enableBasicAuthorization(token);

            // Verify user using store token
            try {
                this.context.user = await this.context.api.getLoggedUser();
                Log.i(`${this.constructor.name}: User ${this.context.user.name} restored (storage object found)`);

                // Synchronize data
                await this.feed();
            }
            catch (e) {
                Log.i(`User token is invalid or has expired`);
                console.info(e);
            }
        }

        // We need user to log in
        if (!this.context.user) {
            Log.i(`User token not found or expired, will need to login again`);
        }
    }

}