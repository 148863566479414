import "./price-picker.scss";
import template from "./price-picker.hbs";
import { IncinetContext } from "../../../context/incinet-context";
import { component, events } from "@incinity/hiyo/decorators.js";
import { PinkoFormPicker } from "@incinity/pinko/components/pinko-form-picker/pinko-form-picker.js";

@component(template)
export class PricePicker extends PinkoFormPicker<IncinetContext> {

    public getValue(data: any): any {
        // Empty data?
        if (data.min == null && data.max == null) {
            // Cleat filter values
            return null;
        }
        else {
            // Set filter value
            return {
                gte: data.min,
                lt: data.max
            };
        }
    }

}