import "./pinko-form-picker.scss";
import template from "./pinko-form-picker.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoPicker } from "../pinko-picker/pinko-picker.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoFormPickerOptions } from "./types.js";
import { Log } from "@incinity/hiyo/log.js";
import { query } from "@incinity/hiyo/decorators.js";
import { PinkoForm } from "../pinko-form/pinko-form.js";

@component(template)
export class PinkoFormPicker<T extends Context = Context, U extends PinkoFormPickerOptions = PinkoFormPickerOptions> extends PinkoPicker<T, U> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public getValue(data: any): any {
        Log.w(`${this.constructor.name}.getValue() not implemented. Forgot to override it?`);
    }

    public async render(): Promise<void> {
        // Super call
        super.render();

        // When form submits, we set the value
        this.form.onSubmit = async (data: any) => {
            // Set value based on form data
            this.setValue(this.getValue(data));

            // OnSubmit handler
            this.onSubmit();
        }
    }

}