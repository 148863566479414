import "./payment-form.scss";
import template from "./payment-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoFormDialog } from "@incinity/pinko/components/pinko-form-dialog/pinko-form-dialog.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { Payment } from "../../../clients/api-client/types.js";

@component(template)
export class PaymentForm extends PinkoFormDialog<IncinetContext> {

    public submit(result?: any): void {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Create data and payment from data
        let data = this.form.getData(true);
        let payment: Payment = { ...data }

        // If foreign currency, we will create conversion object and recalculate amount in CZK
        if (data.currency != "CZK") {
            // Create conversion object
            payment.conversion = {
                amount: data.amount,
                currency: data.currency,
                rate: this.context.data.conversion.find(x => x.currency == data.currency)?.rate,
            }

            // Recalculate payment in CZK
            payment.amount = payment.conversion.amount * payment.conversion.rate;
        }

        // Remove currency field from root
        delete (<any>payment).currency;

        // Submit payment as result
        super.submit(payment);
    }
}