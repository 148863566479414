import "./pinko-paging.scss";
import template from "./pinko-paging.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { PinkoPagingOptions } from "./types.js";

@component(template)
export class PinkoPaging extends PinkoComponent<Context, PinkoPagingOptions> {

    // Properties
    public summary: string;

    public summarize(data: any[]): void {
        // Summarizer not defined?
        if (!this.options.summarizer) {
            return;
        }

        // build summary
        this.summary = this.options.summarizer(data);
    }

}