import "./pinko-filter.scss";
import template from "./pinko-filter.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { FilterItem, PinkoFilterOptions } from "./types.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoSearch } from "../pinko-search/pinko-search.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { SearchItem } from "../pinko-search/types.js";

@component(template)
export class PinkoFilter extends PinkoComponent<Context, PinkoFilterOptions> {

    // Components
    public search: PinkoSearch;

    // Event handling methods
    public onSearch(data: any): void {};
    public onSelect(data: any): void {};
    public onItemSelect(item: FilterItem): void {};

    public onCreate() {
        // Create components
        this.createSearch();
    }

    public createSearch(): void {
        // Create search component
        this.search = new PinkoSearch(this.context, {
            name: "search",
            placeholder: "placeholders.search",
            resolver:  this.options.resolver
        });

        // Delegate select to handler
        this.search.onSelect = (item: SearchItem) => {
            this.onSelect(item.data);
        }

        // Delegate search to handler
        this.search.onSubmit = () => {
            this.onSearch(this.getData());
        }
    }

    public onRender(): void {
        // Append filter fields if defined
        if (this.options.fields) {
            for (let field of this.options.fields) {
                // Add to dom
                field.appendTo(this.querySelector("div.fieldset"));

                // Delegate search to handler
                field.onSubmit = () => {
                    this.onSearch(this.getData());
                }
            }
        }
    }

    public setSummary(summary: any) {
        // Set summary to options to parse it on render
        this.options.summary = summary;

        // Empty summary
        if (!this.options.summary) {
            return;
        }

        // Iterate all tabs to update labels
        this.querySelectorAll("div.tabs div.tab").forEach((element: HTMLElement, i: number) => {
            // Get corresponding tab
            let tab = this.options.tabs[i];

            // Update tab text with summary or zero if undefined
            element.innerHTML = `${tab.label} <span>${this.options.summary[tab.summary] || 0}</span>`;
        });
    }

    public selectItem(i: number): void {
        // Get item
        let item = this.options.items[i];

        // OnItemSelect handler
        this.onItemSelect(item);
    }

    public selectTab(i: number): void {
        // Already selected?
        if (this.options.tabs[i].selected) {
            return;
        }

        // Unselect all
        this.options.tabs.forEach(x => x.selected = false);

        // Selected tab
        this.options.tabs[i].selected = true;

        // Redraw
        this.querySelectorAll("div.tab").forEach((e, i) => {
            e.classList.toggle("tab-selected", this.options.tabs[i].selected);
        });

        // OnClick handler
        this.onSearch(this.getData());
    }

    public getData(): any {
        // Data object with values
        let data = {};

        // Get data from fields if defined
        if (this.options.fields) {
            for (let field of this.options.fields) {
                if (field.options.value != null) {
                    data[field.options.name] = field.options.value;
                }
            }
        }

        // Selected tab
        let tab = this.options.tabs?.find(x => x.selected);
        if (tab?.data != null) {
            data = {
                ...data,
                ...tab.data
            }
        }

        // Plus select
        if (this.search.options.value != null) {
            data[this.search.options.name] = this.search.options.value;
        }

        return data;
    }

}