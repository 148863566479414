// General stylesheets are imported here.
// Beware of all SASS code that generates CSS output only ONCE nad here.
import "@incinity/pinko/styles/index.scss";

// Create component map to register decorator
// Decorators are not executed on import statement
import { PinkoTable } from "@incinity/pinko/components/pinko-table/pinko-table.js";
import { PinkoInput } from "@incinity/pinko/components/pinko-input/pinko-input.js";
import { PinkoForm } from "@incinity/pinko/components/pinko-form/pinko-form.js";
import { PinkoButton } from "@incinity/pinko/components/pinko-button/pinko-button.js";
import { IncinetApp } from "./components/common/incinet-app/incinet-app.js";
import { LoginPage } from "./components/common/login-page/login-page.js";
import { PartnerBrowser } from "./components/partners/partner-browser/partner-browser.js";
import { PinkoFilter } from "@incinity/pinko/components/pinko-filter/pinko-filter.js";
import { PinkoIcon } from "@incinity/pinko/components/pinko-icon/pinko-icon.js";
import { PinkoTag } from "@incinity/pinko/components/pinko-tag/pinko-tag.js";
import { PinkoSearch } from "@incinity/pinko/components/pinko-search/pinko-search.js";
import { PinkoAction } from "@incinity/pinko/components/pinko-action/pinko-action.js";
import { PinkoSelect } from "@incinity/pinko/components/pinko-select/pinko-select.js";
import { PinkoPreview } from "@incinity/pinko/components/pinko-preview/pinko-preview.js";
import { PartnerPreview } from "./components/partners/partner-preview/partner-preview.js";
import { PinkoTabs } from "@incinity/pinko/components/pinko-tabs/pinko-tabs.js";
import { PinkoSidebar } from "@incinity/pinko/components/pinko-sidebar/pinko-sidebar.js";
import { PinkoOverlay } from "@incinity/pinko/components/pinko-overlay/pinko-overlay.js";
import { PinkoDialog } from "@incinity/pinko/components/pinko-dialog/pinko-dialog.js";
import { PartnerForm } from "./components/partners/partner-form/partner-form.js";
import { PartnerAddressForm } from "./components/partners/partner-address-form/partner-address-form.js";
import { PinkoConfirm } from "@incinity/pinko/components/pinko-confirm/pinko-confirm.js";
import { PartnerContactForm } from "./components/partners/partner-contact-form/partner-contact-form.js";
import { PinkoSection } from "@incinity/pinko/components/pinko-section/pinko-section.js";
import { OfferBrowser } from "./components/offers/offer-browser/offer-browser.js";
import { PartnerSelect } from "./components/partners/partner-select/partner-select.js";
import { PinkoTitle } from "./@incinity/pinko/components/pinko-title/pinko-title.js";
import { PinkoSymbol } from "./@incinity/pinko/components/pinko-symbol/pinko-symbol.js";
import { PinkoMenu } from "./@incinity/pinko/components/pinko-menu/pinko-menu.js";
import { Messages } from "@incinity/hiyo/messages";
import messagesEn from "./messages/messages.en.json";
import { Templates } from "@incinity/hiyo/templates.js";
import { IncinetContext } from "./context/incinet-context.js";
import { PinkoDate } from "./@incinity/pinko/components/pinko-date/pinko-date.js";
import { PinkoAcronym } from "./@incinity/pinko/components/pinko-acronym/pinko-acronym.js";
import { InvoicingBrowser } from "./components/invoicing/invoicing-browser/invoicing-browser.js";
import { PinkoBar } from "./@incinity/pinko/components/pinko-bar/pinko-bar.js";

export const DEFINE_MAP: any = {
    PinkoOverlay,
    PinkoDialog,
    PinkoTable,
    PinkoInput,
    PinkoSelect,
    PinkoDate,
    PinkoForm,
    PinkoButton,
    PinkoFilter,
    PinkoIcon,
    PinkoTag,
    PinkoSearch,
    PinkoAction,
    PinkoPreview,
    PinkoTabs,
    PinkoSidebar,
    PinkoConfirm,
    PinkoSection,
    PinkoTitle,
    PartnerPreview,
    PinkoMenu,
    PinkoBar,
    LoginPage,
    PartnerBrowser,
    PartnerForm,
    PartnerAddressForm,
    PartnerContactForm,
    PartnerSelect,
    OfferBrowser,
    PinkoSymbol,
    PinkoAcronym,
    InvoicingBrowser
}

// Add localization
Messages.add("en", messagesEn);
Messages.add("cs", messagesEn);
Messages.guessLang();

// Register Handlebars helpers
Templates.registerHelpers();

// Create app component end attach to the body (remove loader node)
document.body.replaceChildren(new IncinetApp(new IncinetContext()));