import "./pinko-select.scss";
import template from "./pinko-select.hbs";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoSelectOptions } from "./types.js";
import { component, events } from "@incinity/hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";
import { PinkoDropdown } from "../pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { Messages } from "@incinity/hiyo/messages.js";
import { ObjectHelper } from "@incinity/hiyo/object-helper.js";
import { Log } from "@incinity/hiyo/log.js";

@component(template)
export class PinkoSelect<T extends Context = Context, U extends PinkoSelectOptions = PinkoSelectOptions> extends PinkoField<T, U> {

    // Properties
    public dropdown: PinkoDropdown;

    // Event handling methods
    public onSelect(item: DropdownItem): void {};

    public onCreate(): void {
        // Messages enums enabled?
        if (this.options.enums) {
            // Get messages store
            let messages = ObjectHelper.getProperty(Messages.getStore(), `enums.${this.options.enums}`);

            // No messages?
            if (!messages) {
                Log.w(`${this.name} has invalid enums reference (${this.options.enums})`);
                return;
            }

            // Build items
            this.options.items = Object.keys(messages).map(x => {
                return {
                    name: x,
                    label: messages[x],
                    selected: Object.keys(this.options.value || {}).includes(x)
                }
            })
        }
    }

    public setValue(value: any): void {
        // Reference (_id and name pair)?
        if (value._id != null && value.name != null) {
            super.setValue({
                [value._id]: value.name
            });
        }
        // Raw value
        else {
            super.setValue(value);
        }
    }

    public show(): void {
        // Stop propagation
        event.stopPropagation();

        // Hide dropdown if visible
        if (this.dropdown?.isConnected) {
            this.dropdown.remove();
            return;
        }

        // Construct new dropdown
        this.dropdown = new PinkoDropdown(this.context, {
            anchor: "TopLeft",
            start: "BottomLeft",
            offset: [0, 0],
            groups: [
                {
                    name: "Main",
                    items: this.options.items
                }
            ]
        });

        // Set same width as element
        this.dropdown.style.width = `${this.offsetWidth}px`;

        // Select value
        this.dropdown.onSelect = (item: DropdownItem) => {
            this.select(item);
        }

        // Show dropdown
        this.dropdown.show(null, this, 0, 60);
    }

    public select(item: DropdownItem): void {
        // create value object
        let value = {};
        value[item.name] = item.label;

        // Set value
        this.setValue(value);

        // Validate self
        this.validate();

        // Submit if enabled
        if (this.options.autosubmit) {
            this.submit();
        }

        // OnSelect handler
        this.onSelect(item);
    }

    public clear(): void {
        // Stop propagation
        event.stopPropagation();

        // Deselect all items
        for (let item of this.options.items) {
            item.selected = false;
        }

        // Clear value
        this.setValue(null);

        // Submit call
        if (this.options.autosubmit) {
            this.submit();
        }
    }

}