import { Router } from "@incinity/hiyo/router.js";
import { RouteHandler } from "@incinity/hiyo/types.js";
import { OfferBrowser } from "../components/offers/offer-browser/offer-browser.js";
import { PinkoMenu } from "@incinity/pinko/components/pinko-menu/pinko-menu";

export class IncinetRouter extends Router {

    public createRoutes() {
        this.addRoute("/", this.openRoot);
        this.addRoute("/partners", this.openPartners);
        this.addRoute("/partners/:id", this.openPartnersPreview);
        this.addRoute("/partners/:id/detail", this.openPartnersDetail);
        this.addRoute("/offers", this.openOffers);
        this.addRoute("/offers/:id", this.openOffersPreview);
        this.addRoute("/offers/:id/detail", this.openOffersDetail);
        this.addRoute("/offers/:id/download", this.openOffersDownload);
        this.addRoute("/invoicing", this.openInvoicing);
        this.addRoute("/invoicing/:id", this.openInvoicingPreview);
    }

    public openRoot: RouteHandler = async (match: any): Promise<void> => {
        await this.openOffers();
    }

    public openPartners: RouteHandler = async (match: any): Promise<void> => {
        this.query<PinkoMenu>("pinko-menu")?.selectItem("PartnerBrowser", true);
        await this.waitFor("partner-browser table");
    }

    public openPartnersPreview: RouteHandler = async (match: any): Promise<void> => {
        await this.openPartners();
        this.query<OfferBrowser>("partner-browser")?.table.selectRow(match.id, true);
    }

    public openPartnersDetail: RouteHandler = async (match: any): Promise<void> => {
        // Open partners browser and detail
        await this.openPartners();
        this.query<OfferBrowser>("partner-browser")?.table.openRow(match.id);
    }

    public openOffers: RouteHandler = async (match: any): Promise<void> => {
        this.query<PinkoMenu>("pinko-menu")?.selectItem("OfferBrowser", true);
        await this.waitFor("offer-browser table");
    }

    public openOffersPreview: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and preview
        await this.openOffers();
        this.query<OfferBrowser>("offer-browser")?.table.selectRow(match.id, true);
    }

    public openOffersDetail: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and detail
        await this.openOffers();
        this.query<OfferBrowser>("offer-browser")?.table.openRow(match.id);
    }

    public openOffersDownload: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and detail
        await this.openOffers();
        this.query<OfferBrowser>("offer-browser")?.table.selectAction(match.id, "Download", true);
    }

    public openInvoicing: RouteHandler = async (match: any): Promise<void> => {
        this.query<PinkoMenu>("pinko-menu")?.selectItem("InvoicingBrowser", true);
        await this.waitFor("invoicing-browser table");
    }

    public openInvoicingPreview: RouteHandler = async (match: any): Promise<void> => {
        // Open offers browser and preview
        await this.openInvoicing();
        this.query<OfferBrowser>("invoicing-browser")?.table.selectRow(match.id, true);
    }
}