import "./invoicing-preview.scss";
import template from "./invoicing-preview.hbs";
import { component, events, query } from "@incinity/hiyo/decorators.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoPreview } from "@incinity/pinko/components/pinko-preview/pinko-preview.js";
import { PinkoTabs } from "@incinity/pinko/components/pinko-tabs/pinko-tabs.js";
import { PinkoDropdown } from "@incinity/pinko/components/pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "@incinity/pinko/components/pinko-dropdown/types.js";
import { Messages } from "@incinity/hiyo/messages.js";
import { InvoicingForm } from "../invoicing-form/invoicing-form.js";
import { InvoicingBrowser } from "../invoicing-browser/invoicing-browser.js";
import { PagedData } from "../../../@incinity/pinko/components/pinko-browser/types.js";

@component(template, true)
@events("keydown")
export class InvoicingPreview extends PinkoPreview<IncinetContext> {

    // Properties
    public activities: PagedData;

    // Components
    public tabs: PinkoTabs;

    // Queries
    @query("footer div.input") public input: HTMLElement;

    public onCreate(): void {
        // Create tabs
        this.tabs = new PinkoTabs(this.context, {
            items: [
                {
                    name: "Overview",
                    label: "Overview",
                    selected: true
                },
                {
                    name: "Activity",
                    label: "Activity"
                }
            ]
        });
    }

    public async onEvent(event: KeyboardEvent): Promise<void> {
        // Comment submitted by CTRL+Enter?
        if (event.target == this.input && event.key == "Enter" && event.ctrlKey) {
            await this.comment();
        }
    }

    public selectStatus(target: HTMLElement): void {
        // Stop propagation
        event.stopPropagation();

        // Create component
        let menu = new PinkoDropdown(this.context, {
            start: "BottomRight",
            anchor: "TopRight",
            groups: [
                {
                    name: "Main",
                    items: Object.keys(Messages.getStore().enums.InvoicingStatus).map(x => ({
                        name: x,
                        label: x,
                        selected: (x == this.options.data.status)
                    }))
                }
            ]
        });

        // Change language
        menu.onSelect = async (item: DropdownItem): Promise<void> => {
            // Show loader
            this.lock();

            // Update status
            await this.context.api.updateResource(`invoicing/${this.options.data._id}/status`, {
                status: item.name
            });

            // Hide loader and redraw
            this.unlock();

            // Reload
            await this.reload();

            // OnUpdate handler
            this.onUpdate();
        }

        // Show menu
        menu.show(target);
    }

    public showForm(): void {
        // Create dialog
        let form = new InvoicingForm(this.context, {
            data: {
                ...this.options.data,
                partner: this.options.data?.partner ? { [this.options.data.partner._id]: this.options.data.partner.name } : null
            }
        });

        // Reload table on submit
        form.onSubmit = async () => {
            // Reload self
            await this.reload();

            // OnUpdate handler
            this.onUpdate();
        }

        // Show modal
        form.showModal();
    }

    public async comment(): Promise<void> {
        // Nothing to submit?
        if (!this.input.innerText?.length) {
            return;
        }

        // Show loader
        this.lock();

        try {
            // API call
            await this.context.api.createResource(`/invoicing/${this.options.data._id}/comments`, {
                comment: this.input.innerText
            })

            // Redraw
            await this.reload();
        }
        catch (e) {
            console.error(e);
        }

        // Hide loader
        this.unlock();
    }

    public async load(): Promise<void> {
        // Load detail
        this.options.data = await this.context.api.getResource(`invoicing/${this.options.data._id}`);

        // Load activities
        this.activities = await this.context.api.createResource("datasets/activities", {
            pagination: {
                pageSize: 100,
                page: 1
            },
            filter: {
                documentId: {
                    eq: this.options.data._id
                }
            },
            sort: {
                timestamp: "desc"
            }
        });
    }

}