import "./partner-select.scss";
import { PinkoSelect } from "@incinity/pinko/components/pinko-select/pinko-select.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { component } from "@incinity/hiyo/decorators.js";
import { DropdownItem } from "@incinity/pinko/components/pinko-dropdown/types.js";
import { PartnerForm } from "../partner-form/partner-form.js";
import { Partner } from "../../../clients/api-client/types.js";

@component(null)
export class PartnerSelect extends PinkoSelect<IncinetContext> {

    public async onRender(): Promise<void> {
        // Load items
        this.options.items = await this.getItems();
    }

    public select(item: DropdownItem) {
        // Overriding select() method to check on what item user clicked
        // "New" item selected?
        if (item.name == "New") {
            // Prevent New item to stay unselected
            item.selected = false;

            // Create dialog from map
            let form = new PartnerForm(this.context);

            // New partner created
            form.onSubmit = async (result: Partner) => {
                // Just load items, no render needed
                this.options.items = await this.getItems();

                // Find new item
                let newItem = this.options.items.find(x => x.name == result._id);

                // Select new item
                newItem.selected = true;

                // Call select on new item
                super.select(newItem);
            }

            // Show modal
            form.showModal();
        }
        // Partner item selected
        else {
            // Call default select then
            super.select(item);
        }
    }

    public async getItems(): Promise<DropdownItem[]> {
        let items: DropdownItem[] = [];

        // Get partners list
        let partners = await this.context.api.getResource("lookups/partners");

        // Add new partner
        items.push({
            name: "New",
            label: "Create new partner",
            icon: "Add",
            separated: true
        });

        // Add all partners
        for (let partner of partners) {
            items.push({
                name: partner._id,
                label: partner.name,
                selected: (this.options.value && Object.keys(this.options.value).includes(partner._id))
            });
        }

        return items;
    }

}