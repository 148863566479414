import "./pinko-date.scss";
import template from "./pinko-date.hbs";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoDateOptions } from "./types.js";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { PinkoCalendar } from "../pinko-calendar/pinko-calendar.js";

@component(template)
export class PinkoDate extends PinkoField<Context, PinkoDateOptions> {

    // Properties
    public calendar: PinkoCalendar;

    // Event handling methods
    public onSelect(item: DropdownItem): void {};

    public show(): void {
        // Stop propagation
        event.stopPropagation();

        // Hide dropdown if visible
        if (this.calendar?.isConnected) {
            this.calendar.remove();
            return;
        }

        // Construct new dropdown
        this.calendar = new PinkoCalendar(this.context, {
            type: "Day",
            anchor: "TopLeft",
            start: "BottomLeft",
            offset: [0, 0],
            from: this.options.value,
            to: this.options.value
        });

        // Select value
        this.calendar.onSelect = (from: string, to?: string) => {
            // Set end date as single value
            this.setValue(to);
        }

        // Show dropdown
        this.calendar.show(null, this, 0, 60);
    }

}