import "./partner-form.scss";
import template from "./partner-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Address, Contact, Partner } from "../../../clients/api-client/types.js";
import { PartnerAddressForm } from "../partner-address-form/partner-address-form.js";
import { PartnerContactForm } from "../partner-contact-form/partner-contact-form.js";
import { PinkoFormDialog } from "@incinity/pinko/components/pinko-form-dialog/pinko-form-dialog.js";
import { ObjectHelper } from "@incinity/hiyo/object-helper.js";
import { IncinetContext } from "../../../context/incinet-context.js";

@component(template)
export class PartnerForm extends PinkoFormDialog<IncinetContext> {

    // Properties
    public partner: Partner;

    public onAttach(): void {
        // Set data or empty project to partner
        // When data available, we create a deep copy not to modify data passed by reference
        this.partner = ObjectHelper.copy(this.options.data) || {};
    }

    public addAddress(): void {
        // Address dialog
        let dialog = new PartnerAddressForm(this.context);

        // Handle data
        dialog.onSubmit = (result: Address) => {
            // Add new address
            this.partner.addresses ? this.partner.addresses.push(result) : this.partner.addresses = [result];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public editAddress(i: number): void {
        // Address dialog
        let dialog = new PartnerAddressForm(this.context, {
            data: this.partner.addresses[i]
        });

        // Handle data
        dialog.onSubmit = (result: Address) => {
            // Assign edited address
            this.partner.addresses[i] = result;

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeAddress(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove address by index
        this.partner.addresses.splice(i, 1);

        // Redraw
        this.render();
    }

    public addContact(): void {
        // Contact dialog
        let dialog = new PartnerContactForm(this.context);

        // Handle data
        dialog.onSubmit = (result: Contact) => {
            // Add new contact
            this.partner.contacts ? this.partner.contacts.push(result) : this.partner.contacts = [result];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public editContact(i: number): void {
        // Contact dialog
        let dialog = new PartnerContactForm(this.context, {
            data: this.partner.contacts[i]
        });

        // Handle data
        dialog.onSubmit = (result: Contact) => {
            // Assign edited contact
            this.partner.contacts[i] = result;

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeContact(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove contact by index
        this.partner.contacts.splice(i, 1);

        // Redraw
        this.render();
    }

    public async trySubmit(): Promise<any> {
        // Merge partner data with form
        this.partner = {
            ...this.partner,
            ...this.form.getData(true)
        }

        // Update call?
        if (this.partner._id) {
            return await this.context.api.updateResource(`partners/${this.partner._id}`, this.partner);
        }
        // Create call
        else {
            return await this.context.api.createResource("partners", this.partner);
        }
    }

}