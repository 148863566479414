import "./user-picker.scss";
import { IncinetContext } from "../../../context/incinet-context.js";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoItemPicker } from "@incinity/pinko/components/pinko-item-picker/pinko-item-picker.js";

@component()
export class UserPicker extends PinkoItemPicker<IncinetContext> {

    public async createItems(): Promise<void> {
        // Clear items
        this.items = [];

        // Get partners list
        let users = await this.context.api.getResource("lookups/users");

        // Add all user
        for (let user of users) {
            this.items.push({
                name: user._id,
                label: user.name,
                selected: (this.options.value && this.options.value.eq == user._id)
            });
        }
    }

}