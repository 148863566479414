import "./partner-picker.scss";
import { IncinetContext } from "../../../context/incinet-context.js";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoItemPicker } from "@incinity/pinko/components/pinko-item-picker/pinko-item-picker.js";

@component()
export class PartnerPicker extends PinkoItemPicker<IncinetContext> {

    public async createItems(): Promise<void> {
        // Clear items
        this.items = [];

        // Get partners list
        let partners = await this.context.api.getResource("lookups/partners");

        // Add all partners
        for (let partner of partners) {
            this.items.push({
                name: partner._id,
                label: partner.name,
                selected: (this.options.value && this.options.value.eq == partner._id)
            });
        }
    }

}