import "./pinko-star-picker.scss";
import template from "./pinko-star-picker.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoPicker } from "../pinko-picker/pinko-picker.js";

@component(template)
export class PinkoStarPicker extends PinkoPicker {

    select() {
        // Set value as selected filter
        this.setValue({
            eq: true
        });

        // OnSubmit handler
        this.onSubmit();
    }

}