import "./pinko-menu.scss";
import template from "./pinko-menu.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Component } from "@incinity/hiyo/component.js";
import { StringHelper } from "@incinity/hiyo/string-helper.js";
import { PinkoDropdown } from "../pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { Router } from "@incinity/hiyo/router.js";
import { PinkoMenuOptions, MenuItem } from "./types";
import { Context } from "@incinity/hiyo/context.js";
import { Log } from "@incinity/hiyo/log.js";

@component(template)
export class PinkoMenu extends Component<Context, PinkoMenuOptions> {

    // Event handling methods
    public onMenuSelect(item: MenuItem): void {};
    public onActionSelect(item: DropdownItem): void {};
    public onUserProfile(): void {};
    public onUserLogout(): void {};

    public selectActions() {
        // Stop event propagation to item click
        event.stopPropagation();

        // Create shortcut menu
        let dropdown = new PinkoDropdown(this.context, {
            anchor: "TopLeft",
            start: "BottomLeft",
            offset: [16, 4],
            stateless: true,
            groups: [
                {
                    name: "Main",
                    items: this.options.actions
                }
            ]
        });

        // Select dropdown item
        dropdown.onSelect = (item: DropdownItem) => {
            // OnActionSelect handler
            this.onActionSelect(item);
        }

        // Show dropdown
        dropdown.show(this.querySelector("div.button-actions"));
    }

    public selectItem(name: string, skipHistory?: boolean): void {
        // Iterate all items and select/deselect them
        this.querySelectorAll("div.items div.item").forEach((e: Element) => {
            e.classList.toggle("item-selected", e.classList.contains(`item-${StringHelper.toKebabCase(name)}`));
        });

        // Get item
        let item = this.options.items.find(x => x.name == name);

        // Not found?
        if (!item) {
            Log.w(`${this.constructor.name}: Item ${name} not found`);
            return;
        }

        // Push history
        if (!skipHistory) {
            Router.pushHistory(item.route);
        }

        // OnMenuSelect handler
        this.onMenuSelect(item)
    }

    public selectUser() {
        // Stop event propagation to item click
        event.stopPropagation();

        // Create shortcut menu
        let dropdown = new PinkoDropdown(this.context, {
            anchor: "BottomLeft",
            start: "TopLeft",
            offset: [16, -4],
            groups: [
                {
                    name: "Main",
                    items: [
                        {
                            name: "Profile",
                            icon: "User",
                            label: "labels.profile"
                        },
                        {
                            name: "Logout",
                            icon: "Logout",
                            label: "labels.logout"
                        }
                    ]
                }
            ]
        });

        // Open shortcut dialog
        dropdown.onSelect = (item: DropdownItem) => {
            // Profile?
            if (item.name == "Profile") {
                // OnUserProfile handler
                this.onUserProfile();
            }

            // Logout?
            if (item.name == "Logout") {
                // OnUserLogout handler
                this.onUserLogout();
            }
        }

        // Show dropdown
        dropdown.show(this.querySelector("div.item-user"));
    }

}