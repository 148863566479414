import "./pinko-tabs.scss";
import template from "./pinko-tabs.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoTabsOptions, TabItem } from "./types.js";
import { StringHelper } from "@incinity/hiyo/string-helper.js";
import { Log } from "@incinity/hiyo/log.js";

@component(template)
export class PinkoTabs extends PinkoComponent<Context, PinkoTabsOptions> {

    // Event handling methods
    public onSelect(item: TabItem): void {}; // Constructor was called

    public onRender() {
        // No tab is selected?
        if (!this.options.items) {
            Log.w(`${this.name}: Items not defined (forgot to set options.items?)`)
            return;
        }

        // Get selected tab
        let selected = this.options.items?.find(x => x.selected);

        // Get all tab contents
        let elements = this.parentComponent.querySelectorAll<HTMLElement>(`[class*="tab-content"]`);

        // Hide them and show only selected one
        for (let element of elements) {
            element.style.display = (selected && element.classList.contains(`tab-content-${StringHelper.toKebabCase(selected.name)}`)) ? "block" : "none";
        }
    }

    public select(i: number): void {
        // Already selected?
        if (this.options.items[i].selected) {
            return;
        }

        // Unselect all
        this.options.items.forEach(x => x.selected = false);

        // Selected tab
        this.options.items[i].selected = true;

        // Redraw
        this.render();

        // OnClick handler
        this.onSelect(this.options.items[i]);
    }

}