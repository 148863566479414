import { Context } from "@incinity/hiyo/context.js";
import { PinkoFieldOptions } from "./types.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { FormatHelper } from "@incinity/hiyo/format-helper.js";
import { Log } from "@incinity/hiyo/log.js";
import { PinkoForm } from "../pinko-form/pinko-form.js";

export abstract class PinkoField<T extends Context = Context, U extends PinkoFieldOptions = PinkoFieldOptions> extends PinkoComponent<T, U> {

    // Event handling methods
    public onSubmit(): void {};
    public onChange(value: any): void {};

    public onCreate(): void {
        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Needs a type conversion when is number?
        if (this.options.value != null && this.options.type == "Number") {
            // We will only put number type into options value if it is really valid number
            this.options.value = FormatHelper.isNumber(this.options.value) ? Number(this.options.value) : this.options.value;
        }
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Redraw
        this.render();
    }

    public setReason(reason: string): void {
        // Sets invalid and reason options
        this.options.invalid = (reason != null);
        this.options.reason = reason;

        // Redraw
        this.render();
    }

    public render(): void {
        // Super call
        super.render();

        // Autofocus first element with autofocus attribute
        this.querySelector<HTMLElement>("*[autofocus]")?.focus();
    }

    public validate(): boolean {
        let reason = null;

        // Required and null?
        if (this.options.required && (this.options.value == null || this.options.value.length == 0)) {
            reason = "validation.valueRequired";
        }

        // Invalid number?
        if (this.options.type == "Number" && this.options.value?.length && !FormatHelper.isNumber(this.options.value)) {
            reason = "validation.invalidNumber";
        }

        // Invalid e-mail?
        if (this.options.type == "Email" && this.options.value?.length && !FormatHelper.isEmail(this.options.value)) {
            reason = "validation.invalidEmail";
        }

        // Invalid phone?
        if (this.options.type == "Phone" && this.options.value?.length  && !FormatHelper.isPhone(this.options.value)) {
            reason = "validation.invalidPhone";
        }

        // Invalid urk?
        if (this.options.type == "Url" && this.options.value?.length  && !FormatHelper.isUrl(this.options.value)) {
            reason = "validation.invalidUrl";
        }

        // Invalid percent?
        if (this.options.type == "Percent" && this.options.value?.length && !FormatHelper.isNumber(this.options.value)) {
            reason = "validation.invalidPercent";
        }

        // Reason has changed?
        if (this.options.reason != reason) {
            // Set new reason and redraw
            this.setReason(reason);
        }

        if (this.options.reason) {
            Log.w(`${this.name}: Validation of ${this.options.name} failed (reason=${this.options.reason}, value=${this.options.value})`);
        }

        // Returns true if input passed the validation
        return (this.options.reason == null);
    }

    public submit(): void {
        // Submit pinko-form component as well if it is found as parent
        if (this.parentComponent instanceof PinkoForm) {
            this.parentComponent.submit();
        }

        // OnSubmit handler
        this.onSubmit();
    }
}