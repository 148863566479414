import { query } from "@incinity/hiyo/decorators.js";
import { PinkoFormDialogOptions } from "./types.js";
import { PinkoDialog } from "../pinko-dialog/pinko-dialog.js";
import { PinkoForm } from "../pinko-form/pinko-form.js";
import { Log } from "@incinity/hiyo/log.js";
import { Context } from "@incinity/hiyo/context.js";

export abstract class PinkoFormDialog<U extends Context = Context, T extends PinkoFormDialogOptions = PinkoFormDialogOptions> extends PinkoDialog<U, T> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public render(): void {
        // This is an important routine to store and restore data when form is rendered.
        // If data comes from options, it will be used on default entry
        let data = this.form?.getData() || this.options.data;

        // When render is called, all form data fields will be redrawn blank
        super.render();

        // Form present in dialog
        if (this.form) {
            // Restore form data
            this.form.setData(data);

            // Add on form submit handler for autosubmit fields
            this.form.onSubmit = async (data: any) => {
                // Submit form data as result
                super.submit(this.form.getData(true));
            }
        }
        // No form might be an error
        else {
            Log.w(`${this.constructor.name}: <pinko-form> not found in dialog. Forgot to embed your inputs into form?`);
        }
    }

    public throwError(field: string, code: string, reason?: string): void {
        throw {
            status: 422,
            response: [
                {
                    field: field,
                    code: code,
                    reason: reason
                }
            ]
        }
    }

    public async trySubmit(): Promise<any> {
        Log.w(`${this.constructor.name}.trySubmit() not implemented. Forgot to override it?`);
    }

    public async submitWithData(): Promise<void> {
            // Validation
            if (!this.form.validate()) {
                return;
            }

            // Submit form data as result
            super.submit(this.form.getData(true));
    }

    public async submitWithTry(): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Show loader
        this.lock();

        try {
            // Call try implementation
            let result = await this.trySubmit();

            // Submit form
            super.submit(result);
        }
        catch (e) {
            if (e.status == 422) {
                // Parse errors to set field reasons
                for (let error of e.response) {
                    let reason = error.code.charAt(0).toLowerCase() + error.code.slice(1);
                    this.form.setReason(error.field, error.reason || `validation.${reason}`);
                }
            }
            else {
                console.error(e);
            }
        }

        // Hide loader
        this.unlock();
    }
}