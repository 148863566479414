import "./invoicing-browser.scss";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoFilter } from "@incinity/pinko/components/pinko-filter/pinko-filter.js";
import { SearchItem } from "@incinity/pinko/components/pinko-search/types.js";
import { PinkoTable } from "@incinity/pinko/components/pinko-table/pinko-table.js";
import { InvoicingPreview } from "../invoicing-preview/invoicing-preview.js";
import { TableAction, TableRow } from "@incinity/pinko/components/pinko-table/types.js";
import { FilterItem } from "@incinity/pinko/components/pinko-filter/types.js";
import { InvoicingForm } from "../invoicing-form/invoicing-form.js";
import { PinkoConfirm } from "@incinity/pinko/components/pinko-confirm/pinko-confirm.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoBrowser, WIDTH_NAME, WIDTH_NUMBER, WIDTH_PRICE, WIDTH_TITLE } from "@incinity/pinko/components/pinko-browser/pinko-browser.js";
import { NumberHelper } from "@incinity/hiyo/number-helper.js";
import { PinkoPaging } from "@incinity/pinko/components/pinko-paging/pinko-paging.js";
import { TemplatePreview } from "../../common/template-preview/template-preview.js";
import { Invoicing } from "../../../clients/api-client/types.js";
import { PricePicker } from "../../common/price-picker/price-picker.js";
import { PartnerPicker } from "../../partners/partner-picker/partner-picker.js";
import { SystemHelper } from "@incinity/hiyo/system-helper.js";

@component()
export class InvoicingBrowser extends PinkoBrowser<IncinetContext> {

    public onAttach(): void {
        // Set options before render and with already passed context
        this.options = {
            http: this.context.api.http,
            url: `${this.context.api.options.host}/api/datasets/invoicing`
        }
    }

    public createFilter() {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "components.InvoicingBrowser.title",
            items: [
                {
                    name: "Add",
                    icon: "Add",
                },
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            fields: [
                new PartnerPicker(this.context, {
                    name: "partner._id",
                    placeholder: "Partner",
                    searchable: true
                }),
                new PricePicker(this.context, {
                    name: "summary.total",
                    placeholder: "Price"
                })
            ],
            tabs: [
                {
                    name: "All",
                    label: "All",
                    summary: "all",
                    selected: true
                },
                {
                    name: "Paid",
                    label: "Paid",
                    summary: "paid",
                    data: {
                        status: {
                            eq: "Paid"
                        }
                    }
                },
                {
                    name: "Unpaid",
                    label: "Unpaid",
                    summary: "Unpaid",
                    data: {
                        status: {
                            ne: "Paid"
                        }
                    }
                }

            ],
            resolver: async (term: string): Promise<SearchItem[]> => {
                // Search invoicing
                let invoicing = await this.context.api.createResource(`search/invoicing`, {
                    pagination: {
                        pageSize: 10,
                        page: 1
                    },
                    filter: {
                        search: term
                    },
                    sort: {
                        title: "asc"
                    }
                });

                // Return as items
                return invoicing.data?.map(x => ({
                    name: x.title,
                    label: `${x.number} ${x.title} ${x.partner.name}`.replace(new RegExp(`(${term})`, "gi"), "<strong>$1</strong>"),
                    data: x
                }));
            }
        });

        // Item selected
        this.filter.onItemSelect = async (item: FilterItem) => {
            // Reload?
            if (item.name == "Reload") {
                await this.load();
            }

            // Add?
            if (item.name == "Add") {
                this.showForm();
            }
        }
    }

    public createTable() {
        // Create table
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Normal",
            height: "100%",
            rows: {
                id: "_id",
                route: "/invoicing/:id",
                decorator: (data: any): string => {
                    // Disabled?
                    if (data.disabled) {
                        return "disabled";
                    }
                    // Expired?
                    else if (new Date(data.expiration).getTime() < Date.now()) {
                        return "error";
                    }
                    // Just default
                    else {
                        return null;
                    }
                }
            },
            columns: [
                {
                    name: "title",
                    type: "String",
                    property: "title",
                    label: "columns.title",
                    width: 340,
                    ellipsis: true,
                    sortable: true,
                    selected: true
                },
                {
                    name: "partner.name",
                    type: "String",
                    property: "partner.name",
                    label: "columns.partner",
                    width: WIDTH_NAME,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "paid",
                    type: "Number",
                    property: "paid",
                    label: "columns.paid",
                    unit: "CZK",
                    width: WIDTH_PRICE,
                    align: "Right",
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "total",
                    type: "Number",
                    property: "total",
                    label: "columns.total",
                    unit: "CZK",
                    width: WIDTH_PRICE,
                    align: "Right",
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "covered",
                    type: "Number",
                    property: "covered",
                    label: "columns.covered",
                    formatter: (value: any, data: any): string => {
                        return `<div class="cell"><pinko-bar percent="${Math.round(data.paid / data.total * 100)}" type="${ data.paid == data.total ? "Active" : "Default"}"></pinko-bar></div>`
                    },
                    width: 120,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "created.timestamp",
                    type: "String",
                    property: "empty",
                    label: "",
                    unit: "days",
                    minWidth: 180,
                    sortable: true
                }
            ],
            actions: [
                {
                    name: "Edit",
                    icon: "Edit",
                    label: "labels.edit",
                    //route: "/invoicing/:id/edit",
                    quick: true
                },
                {
                    name: "Delete",
                    icon: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // Action selected
        this.table.onActionSelect = async (row: TableRow, action: TableAction) => {
            // Copy?
            if (action.name == "Copy") {
                this.showForm(row.data);
            }

            // Edit?
            if (action.name == "Edit") {
                this.showForm(row.data);
            }

            // Detail?
            if (action.name == "Detail") {
                this.showDetail(row.data);
            }

            // Print?
            if (action.name == "Print") {
                await this.showPrint(row.data);
            }

            // Delete?
            if (action.name == "Delete") {
                this.showDelete(row.data);
            }
        }
    }

    public createPaging(): void {
        // Create paging
        this.paging = new PinkoPaging(this.context, {
            summarizer: (data: any[]) => {
                // Get sum
                let sum = data?.length ? data.map(x => x.total).reduce((a, b) => a + b, 0) : 0;

                // Return sum in EUR
                return `Total of ${NumberHelper.toNumber(sum)} EUR`;
            }
        });
    }

    public createPreview(): void {
        // Create preview
        this.preview = new InvoicingPreview(this.context);
    }

    /*public createDetail() {
        // Create detail
        this.detail = new InvoicingDetail(this.context);
    }*/

    public showDelete(data: Invoicing): void {
        // Create confirm dialog
        let confirm = new PinkoConfirm(this.context, {
            title: "Really delete?",
            text: "Are you sure you want to delete?",
            labelConfirm: "labels.delete",
            escalated: true
        });

        // Delete on confirm
        confirm.onConfirm = async () => {
            // Lock component
            this.lock();

            // API call
            await this.context.api.deleteResource(`invoicing/${data._id}`);

            // Unlock component
            this.unlock();

            // Close preview if open
            if (this.preview?.options?.data?._id == data._id) {
                this.preview.remove();
            }

            // Reload self
            await this.load();
        }

        // Show modal
        confirm.showModal();
    }

    public showDetail(data: Invoicing): void {
        // Invoicing have no detail, so we need to overwrite showDetail() to show form
        this.showForm(data);
    }

    public showForm(data?: Invoicing): void {
        // Create dialog
        let form = new InvoicingForm(this.context, {
            data: data
        });

        // Reload table on submit
        form.onSubmit = async () => {
            // Reload self
            await this.load();

            // Reload preview
            if (this.preview.isConnected && this.preview?.options.data?._id == data?._id) {
                await this.preview.reload();
            }
        }

        // Show modal
        form.showModal();
    }

    public async copyForm(data: Invoicing): Promise<void> {
        // Get full invoicing with items
        let invoicing = await this.context.api.getResource(`invoicing/${data._id}`);

        // Delete id end rename text
        delete invoicing._id;
        invoicing.title = "ss";

        // Open new form
        this.showForm(invoicing);
    }

    public async showPrint(data: Invoicing): Promise<void> {
        // Load data for full detail
        let invoicing = await this.context.api.getResource(`invoicing/${data._id}`);

        // Create preview dialog
        let preview = new TemplatePreview(this.context, {
            key: "InvoicingPdf",
            data: invoicing
        });

        // Show
        preview.showModal();
    }

    public async toggleStarred(id: string): Promise<void> {
        // Prevent row select
        event.stopPropagation();

        // Get star element
        let star = this.table.querySelector(`div.star-${id}`);

        // Toggle selected state
        let starred = star.classList.toggle("star-selected");
        let filtered = this.filter.getData()["users.starred"];

        // If unselected wait for better user experience
        if (!starred && filtered) {
            await SystemHelper.sleep(300);
        }

        // Update starred flag ad the background
        // We will not lock/unlock screen or render whole list to avoid flickering
        await this.context.api.updateResource(`invoicing/${id}/starred`, {
            starred: star.classList.contains("star-selected")
        });

        // If unselected wait for better user experience
        if (!starred && filtered) {
            await this.load();
        }

    }
}